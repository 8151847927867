<template>
	<div>
		<div class="text-right" style="margin: 15px 0px 15px 0px;">
			<button type="submit" class="btn btn-primary font-weight-bold"  @click="vueOneChanged()">
				<b v-if="vueOne"><i class="fa fa-list-alt"></i> Afficher en groupe </b>
				<b v-else><i class="fa fa-bars"></i> Afficher sans groupe  </b>
			</button>
		</div> 
		<div class="row" v-if="vueOne">
			<div class="col-md-12">
				<template>
					
					<v-card>
						<b-container fluid>
							<b-table show-empty small stacked="md" :items="itemsData"  :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered"    >
									<template v-slot:cell(vehicle)="row">
										
										<div class="row"  v-if="itemsDataCars[row.item.id]">
											<div class="col-md-2">
												<v-badge avatar left overlap>
													<template v-slot:badge>
														<v-avatar>
															<b-badge href="javascript:;" :variant="itemsDataCars[row.item.id].color_ignition"  style="color:transparent">X</b-badge>
														</v-avatar>
													</template>
												
													<v-avatar size="40" v-if="itemsDataCars[row.item.id].img_constructeur">
														<v-img :src="itemsDataCars[row.item.id].img_constructeur"></v-img>
													</v-avatar>
													<v-avatar size="40" v-else>
														<v-img src="media/icon/cars.png"></v-img>
													</v-avatar>
												</v-badge>
											</div>
											<div class="col-md-10">
												<b style="font-size: 11px;">
													{{ row.item.vehicle }}</b>
													{{ row.item.immatriculation }}<br>
												<span v-if="itemsDataCars[row.item.id].get_gps_status_info">
													<font-awesome-icon style="color:#1BC5BD" class="mr-2" :icon="['far', 'wifi']"/>
												</span>
												<span v-if="!itemsDataCars[row.item.id].get_gps_status_info">
													<font-awesome-icon style="color:#F64E60" class="mr-2" :icon="['far', 'wifi-slash']"/>
												</span>
												<span :style="{color : itemsDataCars[row.item.id].color_motion}">
													<font-awesome-icon class="mr-2" :icon="['far', 'road']"/>
												</span>
											</div>
										</div>
									</template>
									<template v-slot:cell(param1_text)="row">
										<div  v-if="itemsDataCars[row.item.id]">
											{{moment(parseFloat(itemsDataCars[row.item.id].rtc )).format("DD/MM/YYYY hh:mm:ss")}}<br>{{ moment(parseFloat(itemsDataCars[row.item.id].rtc)).fromNow() }}<br>{{ row.item.param1_text }}<br>
										</div>
									</template>
									<template v-slot:cell(odometer_text)="row">
										<div  v-if="itemsDataCars[row.item.id]">
											{{itemsDataCars[row.item.id].odometer_text}}
										</div>
									</template>
									<template v-slot:cell(adresse)="row">
										<div  v-if="itemsDataCars[row.item.id]">
											<a target="_blank" :href="'http://maps.google.com/?q='+itemsDataCars[row.item.id].location.lat+','+itemsDataCars[row.item.id].location.lng">{{itemsDataCars[row.item.id].adresse}}</a>
										</div>
									</template>
									<template v-slot:cell(range_last_ignition)="row">
										<div  v-if="itemsDataCars[row.item.id]">
											{{itemsDataCars[row.item.id].range_last_ignition}}
										</div>
									</template>
									<template v-slot:cell(speed_text)="row">
										<div  v-if="itemsDataCars[row.item.id]">
											{{itemsDataCars[row.item.id].speed_text}}
										</div>
									</template>
									<template #cell(show_details)="row">
										
										<button class="btn mr-2 btn-sm" size="sm" @click="row.toggleDetails">
											<b v-if="!row.detailsShowing">
												<font-awesome-icon style="color:#1BC5BD" class="mr-2" :icon="['fas', 'eye']"/>
											</b>
											<b v-if="row.detailsShowing">
												<font-awesome-icon style="color:#F64E60" class="mr-2" :icon="['fas', 'eye-slash']"/>
											</b>
										</button>
									</template>
										<template #row-details="row">
											<table class="table b-table table-sm b-table-stacked-md">
												<thead>
													<tr>
														<td>Numéro de satellite</td>
														<td>Altitude</td>
														<td>Position de la pédale d'accélération</td>
														<td>Niveau de carburant</td>
														<td>Tension d'alimentation</td>
														<td>RPM</td>
													</tr> 
												</thead>
												<tbody>
													<tr>
														<td>{{itemsDataCars[row.item.id].gps_number_sat}} satellites</td>
														<td>{{itemsDataCars[row.item.id].gps_altitude}} Mètres</td>
														<td>{{itemsDataCars[row.item.id].J1939_Acceleration_pedal_position}} %</td>
														<td>{{itemsDataCars[row.item.id].J1939_Fuel_level}} % </td>
														<td>{{itemsDataCars[row.item.id].main_power_voltage}} V</td>
														<td>{{itemsDataCars[row.item.id].J1939_Engine_Speed}} tr/min</td>
													</tr>
												</tbody>
											</table>
											<l-map
											  style="height: 300px; width: 100%"
											  :zoom="zoom"
											  :center="[itemsDataCars[row.item.id].location.lat,itemsDataCars[row.item.id].location.lng]"
											  
											>
												<l-marker v-if="itemsDataCars[row.item.id].ignition == 0" :lat-lng="[itemsDataCars[row.item.id].location.lat,itemsDataCars[row.item.id].location.lng]" :icon="icon"> </l-marker>
												<v-rotated-marker v-else 
													:lat-lng="[itemsDataCars[row.item.id].location.lat,itemsDataCars[row.item.id].location.lng]" 
													:icon="icon_move" 
													
													:rotationAngle="itemsDataCars[row.item.id].heading">
												</v-rotated-marker>
												<l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
											</l-map>
											<br>
											<br>
											<!--<div class="row">
												<div class="col-6">
													<b-card v-if="itemsDataCars[row.item.id]">
														<l-map
														  style="height: 300px; width: 100%"
														  :zoom="zoom"
														  :center="[itemsDataCars[row.item.id].location.lat,itemsDataCars[row.item.id].location.lng]"
														  
														>
															<l-marker v-if="itemsDataCars[row.item.id].ignition == 0" :lat-lng="[itemsDataCars[row.item.id].location.lat,itemsDataCars[row.item.id].location.lng]" :icon="icon"> </l-marker>
															<v-rotated-marker v-else 
																:lat-lng="[itemsDataCars[row.item.id].location.lat,itemsDataCars[row.item.id].location.lng]" 
																:icon="icon_move" 
																
																:rotationAngle="itemsDataCars[row.item.id].heading">
															</v-rotated-marker>
															<l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
														</l-map>

													  <b-button size="sm" @click="row.toggleDetails">Cacher</b-button>
													</b-card>
												</div>
												<div class="col-6">
													<b-card v-if="itemsDataCars[row.item.id]">
														
														<div class="row">
															<div class="col-3">
																<img :src="itemsDataCars[row.item.id].img" style="width: 150px;">
																<br><b>{{itemsDataCars[row.item.id].devise}}</b>
																<br><b>{{itemsDataCars[row.item.id].obd_ignition}}</b>
															</div>
															<div class="col-9">
																<b>IMEI : {{itemsDataCars[row.item.id].mdmid}}</b>
																<br>
																<b>Sat : {{itemsDataCars[row.item.id].gps_number_sat}}</b>
																<br>
																<b>ADR :<a target="_blank" :href="'http://maps.google.com/?q='+itemsDataCars[row.item.id].location.lat+','+itemsDataCars[row.item.id].location.lng">{{itemsDataCars[row.item.id].adresse}}</a></b>
																<br>
																<b>gps_altitude : {{itemsDataCars[row.item.id].gps_altitude}} Metre</b>
																<br>
																<b>J1939_Acceleration_pedal_position : {{itemsDataCars[row.item.id].J1939_Acceleration_pedal_position}}</b>
																<br>
																<b>J1939_Fuel_level <font-awesome-icon style="color:#000" class="mr-2" :icon="['fas', 'gas-pump']"/> : {{itemsDataCars[row.item.id].J1939_Fuel_level }}</b>
																<br>
																<b>main_power_voltage : {{itemsDataCars[row.item.id].main_power_voltage}}</b>
																<br>
																<b>J1939_Engine_Speed : {{itemsDataCars[row.item.id].J1939_Engine_Speed}}</b>
																
															</div>
														</div>
													</b-card>
												</div>
											</div>-->
											
											
										</template>
								</b-table>
							<!-- Info modal -->
						</b-container>
					</v-card>
				</template>
				<br>
				<!--<template>
				
					<v-card>
						<v-card-title class="indigo white--text headline">
							{{$tc("OVERVIEW.TEXT.LIVE")}}
						</v-card-title>
						<v-row class="pa-4" justify="space-between">
							<v-col cols="4">
								<v-treeview :active.sync="active" :items="items" :load-children="fetchUsers" :open.sync="open" activatable color="warning" open-on-click transition>
									<template v-slot:prepend="{ item }">
										<v-icon v-if="!item.children">
											mdi-account
										</v-icon>
									</template>
								</v-treeview>
							</v-col>

							<v-divider vertical></v-divider>

							<v-col class="d-flex text-center">
							<v-scroll-y-transition mode="out-in">
								<div v-if="!selected" class="title grey--text text--lighten-1 font-weight-light" style="align-self: center;">
									{{selected}}
								</div>
								
								<v-card v-else :key="selected.id" class="pt-6 mx-auto" flat>
									{{selected}}
									<v-card>
																		<v-tabs v-model="tab"  centered cyan icons-and-text>
																			<v-tabs-slider></v-tabs-slider>

																			<v-tab href="#tab-1">
																				Aperçu
																				<v-icon>mdi-phone</v-icon>
																			</v-tab>
																			<v-tab href="#tab-2">
																				Conducteurs
																				<v-icon>mdi-heart</v-icon>
																			</v-tab>
																			<v-tab href="#tab-3">
																				Données du véhicule
																				<v-icon>mdi-account-box</v-icon>
																			</v-tab>
																			<v-tab href="#tab-4">
																				Alert
																				<v-icon>mdi-account-box</v-icon>
																			</v-tab>
																		</v-tabs>
																		<v-tabs-items v-model="tab">
																			<v-tab-item value="tab-1">
																				<v-card flat>
																					<v-card-text>
																						<v-avatar v-if="avatar" size="88">
																							<v-img :src="`https://avataaars.io/${avatar}`" class="mb-6"></v-img>
																						</v-avatar>
																						À l'arrêt depuis 3 mois
																						<br/>
																						Dernier message : 27/08/2020 15:59:12<br/>
																						depuis 3 mois
																						<br/>
																						Odomètre : 11 251 Km
																						<br/>
																						Moteur & Mouvement
																						<br/>
																						Vitesse : 123 Km/h
																					</v-card-text>
																				</v-card>
																			</v-tab-item>
																			<v-tab-item value="tab-2">
																				<v-card flat>
																					<v-card-text>{{ text }}</v-card-text>
																				</v-card>
																			</v-tab-item>
																		</v-tabs-items>
																	</v-card>
								</v-card>
							</v-scroll-y-transition>
							</v-col>
						</v-row>
					</v-card>
				</template>-->
			</div>
		</div>
		<div class="row" v-if="!vueOne">
			<div class="col-md-12" style="background: rgb(255, 255, 255); border-radius: 4px; margin: 10px 23px 6px 1px;padding: 4px 0px 22px 0px;" >
				<div >
					<b-navbar toggleable="lg" type="dark" variant="info" v-b-toggle="'collapse-0'">
						<b-navbar-brand href="javascript:;"  >-</b-navbar-brand>
					</b-navbar>
				</div>
				<b-collapse visible  v-bind:id="'collapse-0'">
					<template>
						<v-card>
							<b-container fluid>
								<!-- User Interface controls -->
								<b-table show-empty small stacked="md" :items="itemsData[0]"  :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered"    >
									<template v-slot:cell(vehicle)="row">
										
										<div class="row"  v-if="itemsDataCars[row.item.id]">
											<div class="col-md-2">
												<v-badge avatar left overlap>
													<template v-slot:badge>
														<v-avatar>
															<b-badge href="javascript:;" :variant="itemsDataCars[row.item.id].color_ignition"  style="color:transparent">X</b-badge>
														</v-avatar>
													</template>
												
													<v-avatar size="40" v-if="itemsDataCars[row.item.id].img_constructeur">
														<v-img :src="itemsDataCars[row.item.id].img_constructeur"></v-img>
													</v-avatar>
													<v-avatar size="40" v-else>
														<v-img src="media/icon/cars.png"></v-img>
													</v-avatar>
												</v-badge>
											</div>
											<div class="col-md-10">
												<b style="font-size: 11px;">
													{{ row.item.vehicle }}</b>
													{{ row.item.immatriculation }}<br>
												<span v-if="itemsDataCars[row.item.id].get_gps_status_info">
													<font-awesome-icon style="color:#1BC5BD" class="mr-2" :icon="['far', 'wifi']"/>
												</span>
												<span v-if="!itemsDataCars[row.item.id].get_gps_status_info">
													<font-awesome-icon style="color:#F64E60" class="mr-2" :icon="['far', 'wifi-slash']"/>
												</span>
												<span :style="{color : itemsDataCars[row.item.id].color_motion}">
													<font-awesome-icon class="mr-2" :icon="['far', 'road']"/>
												</span>
											</div>
										</div>
									</template>
									<template v-slot:cell(param1_text)="row">
										<div  v-if="itemsDataCars[row.item.id]">
											{{moment(parseFloat(itemsDataCars[row.item.id].rtc )).format("DD/MM/YYYY hh:mm:ss")}}<br>{{ moment(parseFloat(itemsDataCars[row.item.id].rtc)).fromNow() }}<br>{{ row.item.param1_text }}<br>
										</div>
									</template>
									<template v-slot:cell(odometer_text)="row">
										<div  v-if="itemsDataCars[row.item.id]">
											{{itemsDataCars[row.item.id].odometer_text}}
										</div>
									</template>
									<template v-slot:cell(adresse)="row">
										<div  v-if="itemsDataCars[row.item.id]">
											<a target="_blank" :href="'http://maps.google.com/?q='+itemsDataCars[row.item.id].location.lat+','+itemsDataCars[row.item.id].location.lng">{{itemsDataCars[row.item.id].adresse}}</a>
										</div>
									</template>
									<template v-slot:cell(range_last_ignition)="row">
										<div  v-if="itemsDataCars[row.item.id]">
											{{itemsDataCars[row.item.id].range_last_ignition}}
										</div>
									</template>
									<template v-slot:cell(speed_text)="row">
										<div  v-if="itemsDataCars[row.item.id]">
											{{itemsDataCars[row.item.id].speed_text}}
										</div>
									</template>
									<template #cell(show_details)="row">
										
										<button class="btn mr-2 btn-sm" size="sm" @click="row.toggleDetails">
											<b v-if="!row.detailsShowing">
												<font-awesome-icon style="color:#1BC5BD" class="mr-2" :icon="['fas', 'eye']"/>
											</b>
											<b v-if="row.detailsShowing">
												<font-awesome-icon style="color:#F64E60" class="mr-2" :icon="['fas', 'eye-slash']"/>
											</b>
										</button>
									</template>
										<template #row-details="row">
											<table class="table b-table table-sm b-table-stacked-md">
												<thead>
													<tr>
														<td>Numéro de satellite</td>
														<td>Altitude</td>
														<td>Position de la pédale d'accélération</td>
														<td>Niveau de carburant</td>
														<td>Tension d'alimentation</td>
														<td>RPM</td>
													</tr> 
												</thead>
												<tbody>
													<tr>
														<td>{{itemsDataCars[row.item.id].gps_number_sat}} satellites</td>
														<td>{{itemsDataCars[row.item.id].gps_altitude}} Mètres</td>
														<td>{{itemsDataCars[row.item.id].J1939_Acceleration_pedal_position}} %</td>
														<td>{{itemsDataCars[row.item.id].J1939_Fuel_level}} % </td>
														<td>{{itemsDataCars[row.item.id].main_power_voltage}} V</td>
														<td>{{itemsDataCars[row.item.id].J1939_Engine_Speed}} tr/min</td>
													</tr>
												</tbody>
											</table>
											<l-map
											  style="height: 300px; width: 100%"
											  :zoom="zoom"
											  :center="[itemsDataCars[row.item.id].location.lat,itemsDataCars[row.item.id].location.lng]"
											  
											>
												<l-marker v-if="itemsDataCars[row.item.id].ignition == 0" :lat-lng="[itemsDataCars[row.item.id].location.lat,itemsDataCars[row.item.id].location.lng]" :icon="icon"> </l-marker>
												<v-rotated-marker v-else 
													:lat-lng="[itemsDataCars[row.item.id].location.lat,itemsDataCars[row.item.id].location.lng]" 
													:icon="icon_move" 
													
													:rotationAngle="itemsDataCars[row.item.id].heading">
												</v-rotated-marker>
												<l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
											</l-map>
											<br>
											<br>
										</template>
								</b-table>
								<!-- Info modal -->
							</b-container>
						</v-card>
					</template>
				</b-collapse>
			</div>
			<div style="background: rgb(255, 255, 255); border-radius: 4px; margin: 10px 23px 6px 1px;padding: 4px 0px 22px 0px;" class="col-md-12" v-for="gp in SelectGpOptions" :key="gp.id">
				<!--children-->
				<div >
					<b-navbar toggleable="lg" type="dark" variant="info" v-b-toggle="'collapse-'+gp.id">
						<b-navbar-brand href="javascript:;" v-html="gp.label" >{{gp.label}}</b-navbar-brand>
					</b-navbar>
				</div>
				<b-collapse  visible v-bind:id="'collapse-'+gp.id">
					<template>
						<v-card>
							<b-container fluid>
								
								<b-table show-empty small stacked="md" :items="itemsData[gp.id]"  :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered"    >
									<template v-slot:cell(vehicle)="row">
										
										<div class="row"  v-if="itemsDataCars[row.item.id]">
											<div class="col-md-2">
												<v-badge avatar left overlap>
													<template v-slot:badge>
														<v-avatar>
															<b-badge href="javascript:;" :variant="itemsDataCars[row.item.id].color_ignition"  style="color:transparent">X</b-badge>
														</v-avatar>
													</template>
												
													<v-avatar size="40" v-if="itemsDataCars[row.item.id].img_constructeur">
														<v-img :src="itemsDataCars[row.item.id].img_constructeur"></v-img>
													</v-avatar>
													<v-avatar size="40" v-else>
														<v-img src="media/icon/cars.png"></v-img>
													</v-avatar>
												</v-badge>
											</div>
											<div class="col-md-10">
												<b style="font-size: 11px;">
													{{ row.item.vehicle }}</b>
													{{ row.item.immatriculation }}<br>
												<span v-if="itemsDataCars[row.item.id].get_gps_status_info">
													<font-awesome-icon style="color:#1BC5BD" class="mr-2" :icon="['far', 'wifi']"/>
												</span>
												<span v-if="!itemsDataCars[row.item.id].get_gps_status_info">
													<font-awesome-icon style="color:#F64E60" class="mr-2" :icon="['far', 'wifi-slash']"/>
												</span>
												<span :style="{color : itemsDataCars[row.item.id].color_motion}">
													<font-awesome-icon class="mr-2" :icon="['far', 'road']"/>
												</span>
											</div>
										</div>
									</template>
									<template v-slot:cell(param1_text)="row">
										<div  v-if="itemsDataCars[row.item.id]">
											{{moment(parseFloat(itemsDataCars[row.item.id].rtc )).format("DD/MM/YYYY hh:mm:ss")}}<br>{{ moment(parseFloat(itemsDataCars[row.item.id].rtc)).fromNow() }}<br>{{ row.item.param1_text }}<br>
										</div>
									</template>
									<template v-slot:cell(odometer_text)="row">
										<div  v-if="itemsDataCars[row.item.id]">
											{{itemsDataCars[row.item.id].odometer_text}}
										</div>
									</template>
									<template v-slot:cell(adresse)="row">
										<div  v-if="itemsDataCars[row.item.id]">
											<a target="_blank" :href="'http://maps.google.com/?q='+itemsDataCars[row.item.id].location.lat+','+itemsDataCars[row.item.id].location.lng">{{itemsDataCars[row.item.id].adresse}}</a>
										</div>
									</template>
									<template v-slot:cell(range_last_ignition)="row">
										<div  v-if="itemsDataCars[row.item.id]">
											{{itemsDataCars[row.item.id].range_last_ignition}}
										</div>
									</template>
									<template v-slot:cell(speed_text)="row">
										<div  v-if="itemsDataCars[row.item.id]">
											{{itemsDataCars[row.item.id].speed_text}}
										</div>
									</template>
									<template #cell(show_details)="row">
										
										<button class="btn mr-2 btn-sm" size="sm" @click="row.toggleDetails">
											<b v-if="!row.detailsShowing">
												<font-awesome-icon style="color:#1BC5BD" class="mr-2" :icon="['fas', 'eye']"/>
											</b>
											<b v-if="row.detailsShowing">
												<font-awesome-icon style="color:#F64E60" class="mr-2" :icon="['fas', 'eye-slash']"/>
											</b>
										</button>
									</template>
										<template #row-details="row">
											<table class="table b-table table-sm b-table-stacked-md">
												<thead>
													<tr>
														<td>Numéro de satellite</td>
														<td>Altitude</td>
														<td>Position de la pédale d'accélération</td>
														<td>Niveau de carburant</td>
														<td>Tension d'alimentation</td>
														<td>RPM</td>
													</tr> 
												</thead>
												<tbody>
													<tr>
														<td>{{itemsDataCars[row.item.id].gps_number_sat}} satellites</td>
														<td>{{itemsDataCars[row.item.id].gps_altitude}} Mètres</td>
														<td>{{itemsDataCars[row.item.id].J1939_Acceleration_pedal_position}} %</td>
														<td>{{itemsDataCars[row.item.id].J1939_Fuel_level}} % </td>
														<td>{{itemsDataCars[row.item.id].main_power_voltage}} V</td>
														<td>{{itemsDataCars[row.item.id].J1939_Engine_Speed}} tr/min</td>
													</tr>
												</tbody>
											</table>
											<l-map
											  style="height: 300px; width: 100%"
											  :zoom="zoom"
											  :center="[itemsDataCars[row.item.id].location.lat,itemsDataCars[row.item.id].location.lng]"
											  
											>
												<l-marker v-if="itemsDataCars[row.item.id].ignition == 0" :lat-lng="[itemsDataCars[row.item.id].location.lat,itemsDataCars[row.item.id].location.lng]" :icon="icon"> </l-marker>
												<v-rotated-marker v-else 
													:lat-lng="[itemsDataCars[row.item.id].location.lat,itemsDataCars[row.item.id].location.lng]" 
													:icon="icon_move" 
													
													:rotationAngle="itemsDataCars[row.item.id].heading">
												</v-rotated-marker>
												<l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
											</l-map>
											<br>
											<br>
										</template>
								</b-table>
							</b-container>
						</v-card>
					</template>
					
					<div v-if="gp.children.length != 0">
					<div  style="margin: 0px 30px 0 30px;" v-for="gpC in gp.children" :key="gpC.id">
						<div class="" >
							<div style="margin: 10px 2px 0px 3px;">
								<b-navbar toggleable="lg" type="dark" variant="info"  v-b-toggle="'collapse-'+gpC.id">
									<b-navbar-brand href="javascript:;" v-html="gpC.label">{{gpC.label}}</b-navbar-brand>
								</b-navbar>
							</div>
						</div>
						<b-collapse visible v-bind:id="'collapse-'+gpC.id">
							<template>
								<v-card>
									<b-container fluid>
										<b-table show-empty small stacked="md" :items="itemsData[gpC.id]"  :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered"    >
									<template v-slot:cell(vehicle)="row">
										
										<div class="row"  v-if="itemsDataCars[row.item.id]">
											<div class="col-md-2">
												<v-badge avatar left overlap>
													<template v-slot:badge>
														<v-avatar>
															<b-badge href="javascript:;" :variant="itemsDataCars[row.item.id].color_ignition"  style="color:transparent">X</b-badge>
														</v-avatar>
													</template>
												
													<v-avatar size="40" v-if="itemsDataCars[row.item.id].img_constructeur">
														<v-img :src="itemsDataCars[row.item.id].img_constructeur"></v-img>
													</v-avatar>
													<v-avatar size="40" v-else>
														<v-img src="media/icon/cars.png"></v-img>
													</v-avatar>
												</v-badge>
											</div>
											<div class="col-md-10">
												<b style="font-size: 11px;">
													{{ row.item.vehicle }}</b>
													{{ row.item.immatriculation }}<br>
												<span v-if="itemsDataCars[row.item.id].get_gps_status_info">
													<font-awesome-icon style="color:#1BC5BD" class="mr-2" :icon="['far', 'wifi']"/>
												</span>
												<span v-if="!itemsDataCars[row.item.id].get_gps_status_info">
													<font-awesome-icon style="color:#F64E60" class="mr-2" :icon="['far', 'wifi-slash']"/>
												</span>
												<span :style="{color : itemsDataCars[row.item.id].color_motion}">
													<font-awesome-icon class="mr-2" :icon="['far', 'road']"/>
												</span>
											</div>
										</div>
									</template>
									<template v-slot:cell(param1_text)="row">
										<div  v-if="itemsDataCars[row.item.id]">
											{{moment(parseFloat(itemsDataCars[row.item.id].rtc )).format("DD/MM/YYYY hh:mm:ss")}}<br>{{ moment(parseFloat(itemsDataCars[row.item.id].rtc)).fromNow() }}<br>{{ row.item.param1_text }}<br>
										</div>
									</template>
									<template v-slot:cell(odometer_text)="row">
										<div  v-if="itemsDataCars[row.item.id]">
											{{itemsDataCars[row.item.id].odometer_text}}
										</div>
									</template>
									<template v-slot:cell(adresse)="row">
										<div  v-if="itemsDataCars[row.item.id]">
											<a target="_blank" :href="'http://maps.google.com/?q='+itemsDataCars[row.item.id].location.lat+','+itemsDataCars[row.item.id].location.lng">{{itemsDataCars[row.item.id].adresse}}</a>
										</div>
									</template>
									<template v-slot:cell(range_last_ignition)="row">
										<div  v-if="itemsDataCars[row.item.id]">
											{{itemsDataCars[row.item.id].range_last_ignition}}
										</div>
									</template>
									<template v-slot:cell(speed_text)="row">
										<div  v-if="itemsDataCars[row.item.id]">
											{{itemsDataCars[row.item.id].speed_text}}
										</div>
									</template>
									<template #cell(show_details)="row">
										
										<button class="btn mr-2 btn-sm" size="sm" @click="row.toggleDetails">
											<b v-if="!row.detailsShowing">
												<font-awesome-icon style="color:#1BC5BD" class="mr-2" :icon="['fas', 'eye']"/>
											</b>
											<b v-if="row.detailsShowing">
												<font-awesome-icon style="color:#F64E60" class="mr-2" :icon="['fas', 'eye-slash']"/>
											</b>
										</button>
									</template>
										<template #row-details="row">
											<table class="table b-table table-sm b-table-stacked-md">
												<thead>
													<tr>
														<td>Numéro de satellite</td>
														<td>Altitude</td>
														<td>Position de la pédale d'accélération</td>
														<td>Niveau de carburant</td>
														<td>Tension d'alimentation</td>
														<td>RPM</td>
													</tr> 
												</thead>
												<tbody>
													<tr>
														<td>{{itemsDataCars[row.item.id].gps_number_sat}} satellites</td>
														<td>{{itemsDataCars[row.item.id].gps_altitude}} Mètres</td>
														<td>{{itemsDataCars[row.item.id].J1939_Acceleration_pedal_position}} %</td>
														<td>{{itemsDataCars[row.item.id].J1939_Fuel_level}} % </td>
														<td>{{itemsDataCars[row.item.id].main_power_voltage}} V</td>
														<td>{{itemsDataCars[row.item.id].J1939_Engine_Speed}} tr/min</td>
													</tr>
												</tbody>
											</table>
											<l-map
											  style="height: 300px; width: 100%"
											  :zoom="zoom"
											  :center="[itemsDataCars[row.item.id].location.lat,itemsDataCars[row.item.id].location.lng]"
											  
											>
												<l-marker v-if="itemsDataCars[row.item.id].ignition == 0" :lat-lng="[itemsDataCars[row.item.id].location.lat,itemsDataCars[row.item.id].location.lng]" :icon="icon"> </l-marker>
												<v-rotated-marker v-else 
													:lat-lng="[itemsDataCars[row.item.id].location.lat,itemsDataCars[row.item.id].location.lng]" 
													:icon="icon_move" 
													
													:rotationAngle="itemsDataCars[row.item.id].heading">
												</v-rotated-marker>
												<l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
											</l-map>
											<br>
											<br>
										</template>
								</b-table>
									</b-container>
								</v-card>
							</template>
						</b-collapse>
					</div>
					</div>
				</b-collapse>
			</div>
		</div>
	</div>
</template>
<script>
import Cookie from "@/core/services/localstorage.module";
import functionAssets from "@/core/services/function.assets.module.js";
import propertiesCar from "@/core/services/properties.car.module.js";
import axios from "axios";
import { LMap, LTileLayer } from 'vue2-leaflet';
import L from 'leaflet';
import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker'


import { library } from '@fortawesome/fontawesome-svg-core'
/*
import { faCircle as fasFaCircle } from '@fortawesome/pro-solid-svg-icons'
import { faCircle as farFaCircle } from '@fortawesome/pro-regular-svg-icons'
import { faCircle as falFaCircle } from '@fortawesome/pro-light-svg-icons'
import { faCircle as fadFaCircle } from '@fortawesome/pro-duotone-svg-icons'*/

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import { faRoad as fasRoad,faWifiSlash as fasWifiSlash,faWifi as fasWifi,faRouter as fasRouter,faGasPump as fasGasPump,faEye as fasEye,faEyeSlash as fasEyeSlash} from '@fortawesome/pro-solid-svg-icons'
import { faRoad as farRoad,faWifiSlash as farWifiSlash,faWifi as farWifi,faRouter as farRouter,faGasPump as farGasPump,faEye as farEye,faEyeSlash as farEyeSlash} from '@fortawesome/pro-regular-svg-icons'
//import {  } from '@fortawesome/pro-solid-svg-icons'

library.add(fasWifi, fasRouter,farWifi,farRouter,farWifiSlash,fasWifiSlash,fasRoad,farRoad,fasGasPump,farGasPump,fasEye,farEye,fasEyeSlash,farEyeSlash)




import moment from 'moment'; 
moment.locale(Cookie.GetCookie('language'));
moment.updateLocale('fr', {
    relativeTime : {
        past: function(input) {
          return input === "à l'instant"
            ? input
            : 'depuis '+input
        },
        s  : "à l'instant",
        future: "dans %s",
        ss : '%d secondes',
        m:  "une minute",
        mm: "%d minutes",
        h:  "une heure",
        hh: "%d heures",
        d:  "un jour",
        dd: "%d jours",
        M:  "un mois",
        MM: "%d mois",
        y:  "une année",
        yy: "%d années"
    }
});
/*const pause = ms => new Promise(resolve => setTimeout(resolve, ms))*/
const avatars = [
    '?accessoriesType=Blank&avatarStyle=Circle&clotheColor=PastelGreen&clotheType=ShirtScoopNeck&eyeType=Wink&eyebrowType=UnibrowNatural&facialHairColor=Black&facialHairType=MoustacheMagnum&hairColor=Platinum&mouthType=Concerned&skinColor=Tanned&topType=Turban',
    '?accessoriesType=Sunglasses&avatarStyle=Circle&clotheColor=Gray02&clotheType=ShirtScoopNeck&eyeType=EyeRoll&eyebrowType=RaisedExcited&facialHairColor=Red&facialHairType=BeardMagestic&hairColor=Red&hatColor=White&mouthType=Twinkle&skinColor=DarkBrown&topType=LongHairBun',
    '?accessoriesType=Prescription02&avatarStyle=Circle&clotheColor=Black&clotheType=ShirtVNeck&eyeType=Surprised&eyebrowType=Angry&facialHairColor=Blonde&facialHairType=Blank&hairColor=Blonde&hatColor=PastelOrange&mouthType=Smile&skinColor=Black&topType=LongHairNotTooLong',
    '?accessoriesType=Round&avatarStyle=Circle&clotheColor=PastelOrange&clotheType=Overall&eyeType=Close&eyebrowType=AngryNatural&facialHairColor=Blonde&facialHairType=Blank&graphicType=Pizza&hairColor=Black&hatColor=PastelBlue&mouthType=Serious&skinColor=Light&topType=LongHairBigHair',
    '?accessoriesType=Kurt&avatarStyle=Circle&clotheColor=Gray01&clotheType=BlazerShirt&eyeType=Surprised&eyebrowType=Default&facialHairColor=Red&facialHairType=Blank&graphicType=Selena&hairColor=Red&hatColor=Blue02&mouthType=Twinkle&skinColor=Pale&topType=LongHairCurly',
  ];

export default {
	components: {
		'font-awesome-icon': FontAwesomeIcon,
		'v-rotated-marker': Vue2LeafletRotatedMarker,
		LMap,
		LTileLayer,
    /*LMarker,*/
    },
	data: () => ({
		url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
		attribution:
		'&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
		zoom: 12,
		center: [47.413220, -1.219482],
		bounds: null,
		icon: L.icon({
			iconUrl: 'media/icon/stoped.png',
			iconSize: [37, 37],
			iconAnchor: [37, 37]
		  }),
		icon_move: L.icon({
			iconUrl: 'media/icon/pos-icon-move.png',
			iconSize: [37, 37],
			iconAnchor: [37, 37]
		  }),
	
		name: "treeselect-6",
		valueGpOptions: [],
		vueOne: true,
		valueGpOptionsSelect: "",
		SelectGpOptions: [],
		FilterGroupeId: [],
		request : null,
		mindata : [],
		mindataShow : [],
		items : [],
		active: [],
		avatar: null,
		open: [],
		users: [],
		itemsData: [],
		itemsDataCars: {},
		totalRows: 1,
        currentPage: 1,
        perPage: 1000000000,
        pageOptions: [10, 50, 100,500],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
		fields: [],
		moment: moment,
		TimeReloadData: 50000,
		TimeReloadDataInterval: 30000,
    }),
	destroyed() {
		this.$destroy() 
		clearInterval(this.TimeReloadDataInterval);
		clearInterval(this.TimeReloadData);
	},
	computed: {
		sortOptions() {
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },
		selected () {
			var mindata = Cookie.GetCookie('mindata');
			for (var i in mindata){
				if(mindata[i].id == this.active[0]){
					//console.log( mindata[i]);
					//live
					return mindata[i];
				}
			}
			return "";
		},
	},
	
	async mounted() {
		if(typeof(Cookie.GetCookie('overview')) != "undefined" && typeof(Cookie.GetCookie('overview')) != undefined){
			this.vueOne = Cookie.GetCookie('overview') != "groupe" ? true : false;
			
		}else{
			Cookie.setCookie('overview',"groupe")
		}
	
	
		axios.defaults.headers["Content-Type"] =  "application/json";
		axios.defaults.withCredentials = true;
		//await this.NewToken();

		
		this.fields = [
			{ key: 'vehicle', label: this._i18n.messages[localStorage.getItem("language")].TEXT_ALL.VEHICLE, sortable: true, sortDirection: 'desc' },
			{ key: 'range_last_ignition', label: this._i18n.messages[localStorage.getItem("language")].TEXT_ALL.STATUS, sortable: false, 'class': 'text-center' },
			{ key: 'param1_text', label: this._i18n.messages[localStorage.getItem("language")].TEXT_ALL.LAST_MESSAGE, sortable: false, 'class': '' },
			{ key: 'odometer_text', label: this._i18n.messages[localStorage.getItem("language")].TEXT_ALL.ODOMETER, sortable: true, 'class': 'text-center' },
			{ key: 'adresse', label: this._i18n.messages[localStorage.getItem("language")].TEXT_ALL.LOCATION, sortable: true, 'class': 'text-center' },
			{ key: 'speed_text', label: this._i18n.messages[localStorage.getItem("language")].TEXT_ALL.SPEED, sortable: false, 'class': 'text-center' },
			{ key: 'show_details', label: "", sortable: false, 'class': 'text-center' },
			//{ key: 'driver', label: this._i18n.messages[localStorage.getItem("language")].TEXT_ALL.DRIVER, sortable: true, 'class': 'text-center' },
			//{ key: 'actions', label: '' }
		]
		this.FilterGroupeId = [];
		await this.InitDataCars();
		//this.GetDataCars();
		this.GroupeGpOptions();
		this.TimeReloadDataInterval = setInterval(() => {
			this.GetDataCars(true);
		}, this.TimeReloadData);
		/*setTimeout(() => {
			this.GetDataCars(false);
		}, 5000);*/
		setInterval(() => {
			this.$forceUpdate();
		}, 3000);
		
	},
	created() {

    },
	methods: {
		/*zoomUpdated (zoom) {
		  this.zoom = zoom;
		},
		centerUpdated (center) {
		  this.center = center;
		},
		boundsUpdated (bounds) {
		  this.bounds = bounds;
		},@update:zoom="zoomUpdated"
												  @update:center="centerUpdated"
												  @update:bounds="boundsUpdated"*/
		vueOneChanged() {
			this.vueOne = !this.vueOne;
			this.InitDataCars();
			this.GroupeGpOptions();
			//this.GetDataCars(false);
			/*setTimeout(() => {
				this.GetDataCars(false);
			}, 1000);*/
			
		},
		async NewToken() {
			this.request = axios.CancelToken.source();
			axios.defaults.cancelToken = this.request.token;
			axios.defaults.withCredentials = true;
		},
		async InitDataCars() {
			var mindata = Cookie.GetCookie('mindata');
			this.itemsData = []; 
			this.itemsData[0] = []; 
			this.mindata = [];
			this.mindataShow = [];
			this.mindataShowGp = [];
			if(!this.vueOne){
				var key = []
				for (var i in mindata){
					
					if(mindata[i].id_groupe == null || mindata[i].id_groupe == 'null'){
						mindata[i].id_groupe = 0;
					}
					
					if(!this.itemsData[mindata[i].id_groupe]){
						this.itemsData[mindata[i].id_groupe] = [];
					}
					this.mindata = [];
					this.itemsData[mindata[i].id_groupe].splice(i+1, 0, {vehicle:mindata[i].name,id:mindata[i].id});
					if(!this.mindataShowGp[mindata[i].id_groupe]){
						this.mindataShowGp[mindata[i].id_groupe] = [];
						key[mindata[i].id_groupe] = 0;
					}
					this.mindataShowGp[mindata[i].id_groupe][mindata[i].id] = key[mindata[i].id_groupe];
					key[mindata[i].id_groupe]  = key[mindata[i].id_groupe] + 1;
					console.log(this.mindataShowGp);
					
				}
			}else{
				key = [];
				this.itemsData = [];
				for (i in mindata){
					
					if(mindata[i].id_groupe == null || mindata[i].id_groupe == 'null'){
						mindata[i].id_groupe = 0;
					}
					
					//if(!this.itemsData[mindata[i].id_groupe]){
						//this.itemsData[mindata[i].id_groupe] = [];
					//}
					this.mindata = [];
					if(this.itemsData.length==0){
						this.itemsData[0] = {vehicle:mindata[i].name,id:mindata[i].id};
					}else{
						this.itemsData.push({vehicle:mindata[i].name,id:mindata[i].id});
					}
					if(!this.mindataShowGp[mindata[i].id_groupe]){
						this.mindataShowGp[mindata[i].id_groupe] = [];
						key[mindata[i].id_groupe] = 0;
					}
					this.mindataShowGp[mindata[i].id_groupe][mindata[i].id] = key[mindata[i].id_groupe];
					key[mindata[i].id_groupe]  = key[mindata[i].id_groupe] + 1;
					console.log(this.mindataShowGp);
					
				}
			}
			//console.log(this.mindata);
			//console.log(this.itemsData);
			this.GetDataCars(false);
			this.totalRows = this.itemsData.length;
			this.perPage = this.itemsData.length+1;
		},
		//https://map.fleetium.com/nominatim/reverse.php?format=jsonv2&action=reverse&lat=35.82288333333333&lon=10.617766666666666&accept-language=fr
		async GetDataCars(action) {
			var mindata = Cookie.GetCookie('mindata');
			//if(this.valueGpOptions.length != 0){
				//mindata = this.mindata;
			//}
			
			for (var i in mindata){
				if(action){
					//await new Promise(resolve => setTimeout(resolve, 800));
					console.log("action");
				}

				axios.post("controleur/core/calldataV2.php?which=vehiclelist&id="+mindata[i].id+"&recursive=true").then(async (response) => {
				//axios.post("https://stream.sayarte.ch/device/"+mindata[i].id).then(async (response) => {
					if(this.vueOne){
						this.itemsDataCars[response.data[0].id] = await propertiesCar.GetData(response.data[0]);
						this.totalRows = this.itemsDataCars.length;
						this.perPage = this.itemsDataCars.length+1;
					}else{
						this.itemsDataCars[response.data[0].id] = await propertiesCar.GetData(response.data[0]);
					}
					//console.log(this.itemsDataCars);
					
				}).catch((error) => {    
					if (error.response) {
						if (error.response.status == 500) {
							Cookie.DeleteCookie();
							this.$router.push('/login');
						}
					}
				});
			}
			this.itemsData = this.itemsData;
			this.perPage = this.itemsData.length;
		},
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length
			this.currentPage = 1
		  },
		async fetchUsers (item) {
			item.children = functionAssets.GetCarsByGp(item.id);
			//console.log(item);
		},
		randomAvatar () {
			this.avatar = avatars[Math.floor(Math.random() * avatars.length)]
		},
		GroupeGpOptions () {
			this.SelectGpOptions = functionAssets.GpCars();
			//console.log(this.SelectGpOptions);
			this.items = functionAssets.GpCars();
			//console.log(this.SelectGpOptions);
		},
		async SelectUpdate () {
			//const request = axios.CancelToken.source();
			this.request.cancel("Optional message");
			await this.NewToken();
			this.itemsData = [];
			this.mindata = [];
			var res = await functionAssets.GetGroupeSubGroup(this.valueGpOptions);
			this.FilterGroupeId = res;
			await this.InitDataCars();
			//console.log(this.items);
		},
	},
}
</script>